export enum State {
  Pending,
  Approved,
  Rejected,
}

export enum Type {
  Breeder,
  Litter,
  Dog,
  Puppy,
}

export enum QuestionVariant {
  Freetext = "Freetext",
  Multiple = "Multiple",
  Exclusive = "Exclusive",
}
